import React, { useState, useEffect } from 'react'
import { getFirestore } from "firebase/firestore";
//import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import i18n from "../../internationalization/i18n";
import { collection, addDoc } from "firebase/firestore";
import { app } from '../../services/firebase';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { graphql, Link, navigate } from 'gatsby';
import Img from "gatsby-image";

// Styles
import * as styles from './member.module.scss'

// Assets
import logo from '../../images/logo-white.svg'

// GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en" ) {
  datoCmsMemberSignup (locale: {eq: $locale}) {
    heading
    heroImage{
      fluid{
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }
}
`

const MemberSignUp = (props) => {

    // Extract page data
    const pageData = props.data.datoCmsMemberSignup;

    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState(false)
    const [formIsValid, setFormIsValid] = useState(false)
    const [gender, setGender] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState(false)
    const [birthday, setBirthday] = useState(false)
    const [year, setYear] = useState(false)
    const [month, setMonth] = useState(false)
    const [day, setDay] = useState(false)
    //const [referral, setReferral] = useState(false)
    const [interest, setInterest] = useState(false)
    const [termsApproved, setTermsApproved] = useState(true)

    const yearOptions = [...Array(100).fill(2012)].map((v, i) => {
        return { value: v - i, label: v - i }
    })

    const monthOptions = Array.from(Array(12), (i, v) => {
        const m = `${v + 1}`.length === 1 ? `0${v + 1}` : `${v + 1}`
        const monthName = new Date(`2020-${m}-02T00:00:00Z`).toLocaleString(props.pageContext.locale, { month: 'long' })
        return { value: `${v + 1}`, label: monthName }
    })

    const dayOptions = Array.from(Array(31), (i, v) => {
        return { value: `${v + 1}`, label: v + 1 }
    })

    const interestOptions = [
        { value: "running", label: t('member.running') },
        { value: "cycling", label: t('member.cycling') },
        { value: "padel", label: t('member.padel') },
        { value: "hiking", label: t('member.hiking') },
        { value: "tennis", label: t('member.tennis') },
        { value: "golf", label: t('member.golf') },
        { value: "ski", label: t('member.ski') },
        { value: "crossfit", label: t('member.crossfit') },
        { value: "climbing", label: t('member.climbing') },
    ]

    // const referralOptions = [
    //     { value: "ad", label: "Annons" },
    //     { value: "instagram", label: "Instagram" },
    //     { value: "email", label: "Email" },
    //     { value: "other", label: "Annat" },
    // ]


    const handleSignUp = (e) => {
        console.log(e)
        setIsLoading(true)
        const data = {
            //uid: result.user.uid,
            email: email,
            phoneNumber: phoneNumber,
            birthday: birthday,
            gender: gender,
            market: props.pageContext.country,
            interest: interest,
            //referral: referral
        }
        try {
            addToFirestore(data)

        } catch (error) {
            console.log(error)
            setIsLoading(false)
            //Add like a something went wrong but take me to sale anyway?
        }
    }

    useEffect(() => {
        let valid = true
        if (!email) {
            valid = false
        }
        if (!gender) {
            valid = false
        }
        if (!phoneNumber && phoneNumber.length < 5) {
            valid = false
        }
        if (!birthday) {
            valid = false
        }
        if (!termsApproved) {
            valid = false
        }
        //console.log({ email, gender, phoneNumber, birthday, referral, interest })
        setFormIsValid(valid)
    }, [email, gender, phoneNumber, birthday, termsApproved])


    useEffect(() => {
        console.log({ day, month, year })
        if (year && month && day) {
            const formattedMonth = month.length === 1 ? `0${month}` : month
            const formattedDay = day.length === 1 ? `0${day}` : day

            const date = `${year}-${formattedMonth}-${formattedDay}`
            setBirthday(date)
        }
    }, [year, month, day])


    const addToFirestore = async (data) => {
        const db = getFirestore(app)
        const docRef = await addDoc(collection(db, 'users'), data)
        console.log("Document written with ID: ", docRef.id)
        navigate(`/${props.pageContext.country}/black-week`)
    }

    const genders = ['female', 'male', 'other', 'wontSay']

    // Select styling

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <span className="material-icons">unfold_more</span>
            </components.DropdownIndicator>
        );
    };

    const customStyles = {

        control: (provided) => ({
            ...provided,
            background: "#353F44",
            color: "#ffffff",
            border: "1px solid rgba(242, 242, 242, 0.15) !important",
            borderRadius: "4px",
            height: '56px',
            outline: 'none',
            padding: "0 0 0 8px"
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#353F44",
        }),
        singleValue: provided => ({
            ...provided,
            color: '#ffffff'
        }),
        option: (provided, { isDisabled, base, isSelected }) => {
            return {
                ...provided,
                backgroundColor: "#353F44",
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? "white" > 2
                            ? 'white'
                            : '#35C7A9'
                        : "#fff",
            };
        },
        multiValue: (styles, { data }) => {
            const color = "#35C7A9";
            return {
                ...styles,
                backgroundColor: "#35C7A9",
                color: "#353F44"
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    }

    return (
        <div className={styles.cdMemberPage}>
            <div className={styles.cdMemberPageLeft}>
                <div className={styles.cdMemberPageLeftContent}>
                    {pageData && pageData.heading && <div className={styles.cdMemberPageHeading} dangerouslySetInnerHTML={{ __html: pageData.heading }}></div>}
                    <form onSubmit={(e) => { e.preventDefault(); handleSignUp(e); }}>
                        <div className={styles.cdMemberInputs}>
                            <p>{t(`member.email`)}*</p>
                            <input className={styles.cdMemberInput} onBlur={(e) => { setEmail(e.target.value) }} placeholder={t('member.emailInput')} type="email" required />
                        </div>
                        <div className={styles.cdMemberInputs}>
                            <p>{t(`member.phone`)}*</p>
                            <input className={styles.cdMemberInput} type="text" placeholder={t('member.phoneInput')} onChange={(e) => { setPhoneNumber(e.target.value) }} />
                        </div>
                        <div className={styles.cdMemberInputs}>
                            <p>{t(`member.birthday`)}*</p>
                            <div className={styles.cdMemberSelects}>
                                <div className={styles.cdMemberSelect}>
                                    <Select
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null
                                        }}
                                        options={dayOptions}
                                        styles={customStyles}
                                        defaultValue={{ value: false, label: t(`member.day`) }}
                                        onChange={(e) => { setDay(e.value) }}
                                    />
                                </div>
                                <div className={styles.cdMemberSelect}>
                                    <Select
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null
                                        }}
                                        options={monthOptions}
                                        styles={customStyles}
                                        defaultValue={{ value: false, label: t(`member.month`) }}
                                        onChange={(e) => { setMonth(e.value) }}
                                    />
                                </div>
                                <div className={styles.cdMemberSelect}>
                                    <Select
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null
                                        }}
                                        options={yearOptions}
                                        styles={customStyles}
                                        defaultValue={{ value: false, label: t(`member.year`) }}
                                        onChange={(e) => { setYear(e.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.cdMemberInputs}>
                            <p>{t(`member.gender`)}*</p>
                            <div className={styles.cdMemberRadios}>
                                {genders.map(g => {
                                    return (
                                        <div className={`${styles.cdMemberRadio} ${g === gender ? styles.cdMemberRadioBoxActive : ''}`} onClick={() => { setGender(g) }} ><div className={styles.cdMemberRadioBox}></div><span>{t(`member.${g}`)}</span></div>
                                    )
                                })}
                            </div>
                        </div>
                        {/* <div className={styles.cdMemberInputs}>
                            <p>{t(`member.ref`)}</p>
                            <Select
                                components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null
                                }}
                                styles={customStyles}
                                options={referralOptions}
                                defaultValue={{ value: false, label: t('member.choose') }}
                                onChange={(e) => { setReferral(e.value) }}
                            />

                        </div> */}
                        <div className={styles.cdMemberInputs}>
                            <p>{t(`member.subject`)}</p>
                            <Select
                                components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null
                                }}
                                styles={customStyles}
                                options={interestOptions}
                                placeholder={t('member.choose')}
                                isMulti={true}
                                isSearchable={false}
                                menuPlacement={'auto'}
                                onChange={(e) => { setInterest(e.map(inte => inte.value).join(',')) }}
                            />
                        </div>
                        <div className={styles.cdMemberInputs}>
                            <label className={styles.cdAccept}>
                                <input type="checkbox" checked={termsApproved ? true : false} onChange={() => { setTermsApproved(!termsApproved) }} />
                                <span className={styles.cdCheckmark}><i className="material-icons">check</i></span>
                                {t(`member.accept1`)}&nbsp;<Link to={`/${props.pageContext.country}/gdpr/`}>{t(`member.accept2`)}</Link>*
                            </label>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <button className={`${styles.cdMemberButton} cd-button`} disabled={!formIsValid || isLoading ? true : false}>{isLoading ? t('thankYou.loading') : t(`member.button`)}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={styles.cdMemberPageRight}>
                <div className={`${styles.cdMemberPageRightImg}`}>
                    <div className={"cd-background-img"}>
                        {pageData && pageData.heroImage && <Img fluid={pageData.heroImage.fluid} alt={pageData.heroImage.alt} />}
                    </div>
                    <img className={styles.cdMemberLogo} src={logo} alt={"Flowlife logo"} />
                </div>
            </div>
        </div>
    )
}

export default MemberSignUp